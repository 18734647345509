.mobile-menu{
    width: 100vw;
    height: 100vh;
    background-color: #795548;
    color: #EFEBE9;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    display: none;
}
.mobile-menu .content{
    height: 100%;
    display: flex;
    align-items: space-around;
    justify-content: space-around;
    flex-direction: column;
}
.mobile-menu .content a{
    text-align: center;
    text-decoration: none;
    color: #EFEBE9;
}
.menu-close{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #EFEBE9;
}